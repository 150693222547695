<template>
  <div class="eh-participant-component">
    <div style="margin-bottom: 10px;">
      <button v-if="!profileEditable" @click="profileEditable = true" class="eh-action-button">{{
          $t("healthProfessional.patientList.medicalDataTab.editProfile")
        }}</button>
      <button v-if="profileEditable" @click="editProfile" class="eh-action-button">{{
          $t("base.basic.save")
        }}</button>
      <button v-if="profileEditable" @click="cancel" class="eh-action-button">{{
          $t("base.basic.cancel")
        }}</button>
    </div>
    <div v-if="medicalData" style="width: 90%; margin: auto">
      <client-medical-profile
        :disabled="!profileEditable"
        v-model="medicalData"
      />
    </div>
    <div style="text-align: center; margin: 20px" v-else>
      <b-spinner />
    </div>
  </div>
</template>
<script>
import { UserProfileLogic } from "../../../Logic/Backend/user-profile";
import ClientMedicalProfile from "../../Profile/ClientMedicalProfile.vue";
export default {
  components: { ClientMedicalProfile },
  data() {
    return {
      medicalData: null,
      profileEditable: false,
    };
  },
  props: {
    participant: {
      type: Object,
    },
  },
  watch: {
    //participant prop változás figyelő
    async participant() {
      //mediaki profil adatok kérés
      await this.getUserMedicalProfile();
      this.profileEditable = false;
    },
  },
  methods: {
    //beteg medikai adatainak kérése
    async getUserMedicalProfile() {
      //kérés indítás
      const userProfileResponse = await UserProfileLogic.getPatientProfile(
        this.participant.UserId,
        "MedicalProfile"
      );
      //sikeres kérés?
      if (!userProfileResponse.Code) {
        //medikai adatok frissítése
        this.medicalData = userProfileResponse;
      } else {
        this.$bvToast.toast(userProfileResponse.Message, {
          //TODO: name szótárazni
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t(
              "healthProfessional.patientList.medicalDataTab.errorName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async editProfile() {
      const editProfileResponse = await UserProfileLogic.setUserProfile(
        { MedicalProfile: this.medicalData },
        this.participant.UserId
      );
      if (!editProfileResponse.Code) {
        this.profileEditable = false;
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("requestResponse.basic.successSave"),
            variant: "success",
            solid: true,
          }
        );
        await this.getUserMedicalProfile();
      } else {
        this.$bvToast.toast(editProfileResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async cancel() {
      this.profileEditable = false;
      await this.getUserMedicalProfile();
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //medikai adatok betöltése
    await this.getUserMedicalProfile();
  },
};
</script>